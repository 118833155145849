.Brands {
  margin: 64px auto;

  h2 {
    height: 100px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logos {
    display: flex;
    justify-content: center;

    svg {
      margin: 0 24px;
    }
  }
}
