@main_color: #333;
@logo_color: #0072ff;
@secondary_color: #a5b8cf;
@hover_background: #f6f8fa;
@error_color: #fe5b6b;
@notify_color: #ffa439;
@focus_color: #787878;
@green_color: #4acb5e;

@brand_color: #0072ff;
@emerald: #4acb5e;
@orange: #ef6544;
@fire_rose_color: #ff017d;
@purple: #6f56e5;
@platinum_color: #1a2b49;
@smoke_color: #8191a5;
@carbon_color: #c1ccdb;
@dirty_jon_snow_color: #e6e9f1;
@snow_color: #f9fbfc;

@vk_color: #4680c2;
@fb_color: #1877f2;
@tg_color: #36aadd;
@viber_color: #665cac;
@ig_color: #f10176;
@wa_color: #25d366;

/**
Точно такая же переменная есть в bootstrap.sass. Меняешь тут - поменяй и там
 */
@grid_gutter_width: 24px;

@grid-breakpoints: {
  xs: 0;
  sm: 576px;
  md: 768px;
  lg: 992px;
  xl: 1200px;
};

@account_badge_spacing: 14px;
@account_small_badge_border_width: 4px;
@default_line_height: 16px;

@font_small: 11px;

/**
  MIXINS
 */
.mixin_box_shadow_block {
  box-shadow: 0 0 0 1px rgba(165, 184, 207, 0.1), 0 8px 16px rgba(165, 184, 207, 0.08);
}
.mixin_box_shadow {
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04), 0 62.9566px 72.7037px rgba(0, 0, 0, 0.0393684),
    0 39.436px 60.3952px rgba(0, 0, 0, 0.0378242), 0 21.9738px 26.706px rgba(0, 0, 0, 0.0351912),
    0 9.33239px 4.959px rgba(0, 0, 0, 0.0311936), 0 1.2653px 0.418345px rgba(0, 0, 0, 0.0253402),
    0px -2.02863px 0px rgba(0, 0, 0, 0.0165283);
}
.mixin_popup_container {
  .mixin_box_shadow();

  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
}
.quickley_link-mixin(@color) {
  color: @color;
  cursor: pointer;

  &:hover {
    color: darken(@color, 5%);
    text-decoration: none;
  }

  &.disabled {
    color: lighten(@color, 20%);
  }
}
.quickley_link {
  .quickley_link-mixin(@brand_color);
}
.quickley_link-error {
  .quickley_link-mixin(@fire_rose_color);
}
.quickley_link-warning {
  .quickley_link-mixin(@orange);
}
.quickley_linkBtn {
  color: @smoke_color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;

  &:hover {
    color: darken(@smoke_color, 5%);
    text-decoration: none;
  }
}
.text_overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
