@import '../variables';

.Reel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../../public/images/polygon.svg') no-repeat center;
  min-height: calc(100vh - 64px);

  @media (max-width: 767px) {
    background: none;
    min-height: auto;
    margin: 200px 0;
  }

  &__header {
    text-align: center;

    p {
      margin-top: 22px;
      color: @smoke_color;
      font-weight: 600;
    }
  }
  &__content {
    margin-top: 22px;
    overflow-y: hidden;
    display: flex;
    align-items: baseline;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;

    &:after {
      content: '';
      padding: 20px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &__sign {
    margin-right: 47px;
    align-self: center;
  }
}

.ReelCard {
  position: relative;
  width: 306px;
  height: 396px;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-right: 24px;
  }

  &__img {
    height: 204px;
    margin-bottom: 20px;
    background: no-repeat center;
    background-size: cover;
    opacity: 0.3;
    filter: blur(2px);

    &:before {
    }

    &_comments {
      background-image: url('../../../public/images/live-chat/support-en.jpg');
    }
    &_chatLanding {
      background-image: url('../../../public/images/live-chat/landing-en.jpg');
    }
    &_managers {
      background-image: url('../../../public/images/live-chat/team-en.jpg');
    }
    &_app {
      background-image: url('../../../public/images/live-chat/mobile-app-en.jpg');
    }
    &_integrations {
      background-image: url('../../../public/images/whatsapp/automation-en.jpg');
    }
    &_contacts {
      background-image: url('../../../public/images/live-chat/contacts-en.jpg');
    }
  }
  h4 {
    margin-top: 24px;
    margin-bottom: 16px;
    min-height: 56px;
    font-size: 24px;
    font-weight: 600;
  }
  p {
    color: @smoke_color;
  }

  &__content {
    padding: 0 24px 24px;

    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }
  &__tag {
    position: absolute;
    height: 60px;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: @smoke_color;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-left: 24px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0,
        rgba(255, 255, 255, 0.6) 75%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: -1;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}
