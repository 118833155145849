.Links {
  margin: 64px auto;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin: 0 24px;
  }
}
