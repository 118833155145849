@import '../variables';
@import './Navigation';

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

ul,
ol {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

button,
input,
select,
textarea {
  font: inherit;
}

@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../public/fonts/Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../public/fonts/Lato-Semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Semibold.woff') format('woff'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../public/fonts/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Black.eot'); /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../public/fonts/Lato-Black.woff2') format('woff2'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Black.woff') format('woff'),
    /* Modern Browsers */ url('../../../public/fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

.grecaptcha-badge {
  visibility: hidden;
}

body {
  color: @platinum_color;
  font-size: 16px;
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.2;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
}

h1 {
  font-size: 36px;
  font-weight: 600;
}

h2 {
  font-size: 30px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 767px) {
  h1 {
    font-size: 24px;
  }

  h2,
  h3 {
    font-size: 18px;
  }
}

.container_top {
  padding-top: 76px;
}

.container,
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 767px) {
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  height: 64px;
  line-height: 40px;

  font-weight: 600;
  background-color: white;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.header-banner-visible {
    top: 64px;
  }
}

.Section_fullView {
  min-height: calc(100vh - 64px);
}

/* FOOTER */
footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #787878;
  margin-top: 60px;
}
.footer__credentials {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #a5b8cf;
  font-size: 11px;
  max-width: 1500px;
}

.footer__lang {
  display: inline-block;
  color: #333;
  margin-left: 2px;
  font-weight: 700;
}

a.footer__lang {
  color: #0072ff;
}

.footer__lang:not(:last-child) {
  padding-right: 4px;
  border-right: 1px solid #a5b8cf;
}

@media (max-width: 767px) {
  footer {
    padding-bottom: 40px;
  }

  .footer__copyright {
    display: none;
  }

  .footer__credentials {
    flex-direction: column;
    align-items: center;
  }

  .footer__langSwitcher:after {
    margin: 30px auto 0;
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    background: url('../../../public/images/quickley-sign.svg') no-repeat center center;
    background-size: contain;
  }
}
/* END FOOTER */

/* BANNER */
.Banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 64px;
  line-height: 40px;

  &__desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 100%;
  }
  &__mobile {
    display: none;
  }
  &__left {
    font-size: 20px;
    font-weight: bold;
  }
  &__discount {
    font-size: 50px;
    font-weight: bold;
  }
  &__text {
    font-weight: bold;

    .Btn {
      margin-left: 16px;
    }
  }

  &_whatsappOffer {
    color: @snow_color;
    background: @platinum_color url('../../../public/images/whatsapp/confetti.svg') repeat-x center
      center;
  }
}
@media (max-width: @grid-breakpoints[xl]) {
  .Banner {
    &__desktop {
      display: none;
    }
    &__mobile {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }
}
/* END BANNER */

/* NAVIGATION */
.Logo {
  display: inline-flex;
  align-items: center;

  font-size: 26px;
}

.Logo:hover {
  color: inherit;
  text-decoration: none;
}

.Logo__img {
  margin-right: 17px;
}

@media (max-width: 767px) {
  .Logo__caption {
    display: none;
  }

  .Logo__img {
    margin-top: 4px;
    margin-right: 0;
  }
}

main {
  margin-top: 64px;
}
/* END NAVIGATION */

/* BTN */
.Btn {
  font-weight: bold;
  border-radius: 4px;
  padding: 6px 36px;
  box-sizing: border-box;
  font-size: 15px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.Btn_primary {
  color: #fff;
  background-color: #0072ff;
}
.Btn_primary:hover {
  color: #fff;
  background-color: #0067e6;
  text-decoration: none;
}
.Btn_white {
  color: #1a2b49;
  background-color: #f9fbfc;
}

.Btn_disabled {
  cursor: not-allowed;
  color: #a5b8cf;
  border: 1px solid #a5b8cf;
}

.Btn_disabled:hover,
.Btn_disabled.Btn_filled {
  color: white;
  background-color: #a5b8cf;
}

.Btn_light {
  color: #fff;
  border: 1px solid #fff;
}

.Btn_light:hover {
  color: #0072ff;
  background-color: #fff;
}
/* BTN END */

/* MOBILE BADGE */
.MobileBadges {
  display: flex;

  a:first-child {
    margin-right: 15px;
  }
}
/* END MOBILE BADGE */
