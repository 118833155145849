@import '../variables';

.Feature {
  max-width: 1500px;
  margin: 256px auto;

  h2 {
    height: 100px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h3 {
    margin-bottom: 20px;
  }
  p {
    color: @smoke_color;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
  }
  ul {
    list-style: none;
    color: @smoke_color;
    margin-bottom: 30px;

    li {
      margin-bottom: 15px;

      &:before {
        content: ' ';
        display: inline-block;
        background-color: @brand_color;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__caption {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 600;
  }
  &__img {
    max-width: 650px;
    width: 100%;

    &_medium {
      max-width: 450px;
    }
  }
  &__comingSoon {
    color: @smoke_color;
    border-radius: 4px;
    border: 1px solid @smoke_color;
    padding: 4px 14px;
    display: inline-block;
  }

  &_reverse & {
    &__body {
      flex-direction: row-reverse;
    }
  }
}

@media (min-width: 768px) {
  .Feature__content {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .Feature {
    margin: 128px auto;
  }
  .Feature__body {
    flex-direction: column !important;
  }
  .Feature__img {
    width: 100%;
  }
  .Feature__content {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .Feature__caption {
    margin-bottom: 23px;
    font-size: 24px;
  }

  .Feature p {
    margin-bottom: 26px;
  }
}
