@import '../variables';

.Blocks {
  max-width: 1500px;
  margin: 128px auto;

  h2 {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    justify-content: space-around;
  }
}
.Block {
  max-width: 300px;
  text-align: center;

  h3 {
    padding: 15px 0;
  }
  p {
    color: @smoke_color;
  }
}

@media (max-width: 767px) {
  .Blocks__content {
    flex-direction: column;
  }
  .Block {
    margin: 0 auto 50px;
  }
  .Block:last-child {
    margin-bottom: 0;
  }
}
