@import '../variables';

.Legal {
  max-width: 800px;
  margin: 0 auto;

  h2 {
    margin-bottom: 100px;
  }
  h3 {
    margin-top: 32px;
  }
  h4 {
    margin-top: 24px;
  }
  p {
    margin: 15px 0;
    color: @platinum_color;
  }
  ul {
    padding-left: 40px;
  }
  a {
    .quickley_link();
  }

  table {
    color: @platinum_color;

    thead {
      text-transform: uppercase;
      font-size: 10px;

      th {
        border: none;
        padding: 0.75rem;
        vertical-align: top;
      }
    }

    tbody {
      tr td {
        border: none;
        box-shadow: 0 -1px @dirty_jon_snow_color;
        padding: 0.75rem;
        vertical-align: top;
      }
    }

    &.table-hover {
      tbody {
        tr:hover {
          color: @platinum_color;
          background-color: @snow_color;
        }
      }
    }
  }
}
