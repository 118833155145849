@import '../variables';

.Pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1024px;
  margin: 0 auto;

  &__header {
    margin: 0 auto 100px;
    text-align: center;

    h1 {
      margin-bottom: 50px;

      b {
        color: @brand_color;
      }
    }
    p {
      max-width: 70%;
      margin: 20px auto 30px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
}

.PlanCard {
  width: 50%;
  font-size: 13px;

  &:first-child & {
    &__description:before {
      display: none;
    }
  }

  &__header {
    position: sticky;
    top: 64px;
    background-color: #fff;
    z-index: 1;
    padding-bottom: 8px;
  }
  &__name {
    color: @brand_color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
  }
  &__price {
    margin-top: 34px;
    margin-bottom: 44px;
    font-size: 36px;
    font-weight: bolder;
  }
  &__currency {
    font-size: 18px;
    font-weight: normal;
  }
  &__description {
    margin-bottom: 24px;
    min-height: 70px;
    font-size: 14px;
    max-width: 95%;

    &:before {
      margin-bottom: 18px;
      display: block;
      content: '';
      width: 24px;
      height: 2px;
      background-color: @carbon_color;
    }
  }
  &__featureListCaption {
    margin-top: 64px;
    margin-bottom: 24px;
    color: @platinum_color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;

    &:first-child {
      margin-top: 24px;
    }
  }
  &__feature {
    height: 37px;
    display: flex;
    min-height: 18px;
    padding: 10px 0;
    align-items: center;
    font-size: 14px;
    position: relative;
  }
  &__featureName {
    margin-right: 10px;

    a {
      color: @brand_color;
    }

    &_hovered {
      &:hover + .PlanCard__featureDescription {
        opacity: 1;
        z-index: 1;
      }

      span {
        border-bottom: 1px dashed @smoke_color;
        cursor: pointer;
      }
    }
  }
  &__featureHelp {
    font-size: 12px;
    color: @smoke_color;
  }
  &__featureDescription {
    position: absolute;
    max-width: 320px;
    font-size: 12px;
    line-height: 18px;
    top: 45px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(165, 184, 207, 0.1), 0 8px 16px rgba(165, 184, 207, 0.08);
    padding: 20px;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.1s ease-in;
    -webkit-transition: opacity 0.1s ease-in;
    -moz-transition: opacity 0.1s ease-in;
    -o-transition: opacity 0.1s ease-in;
  }
  &__cta {
    display: flex;
    flex-direction: column;
    max-width: 260px;
  }
  &__ctaText {
    color: @smoke_color;
    text-align: center;
    margin-top: 8px;
  }
}

@media (min-width: 768px) {
  .Pricing__description {
    margin: 0 auto;
    width: 373px;
  }
  .PlanCard:not(.PlanCard_dummy) .PlanCard__featureListCaption {
    visibility: hidden;
  }
  .PlanCard:not(.PlanCard_dummy) .PlanCard__featureName {
    display: none;
  }
  .PlanCard .Btn {
    min-width: 128px;
  }
}

@media (max-width: 767px) {
  .Pricing {
    padding-top: 0;
  }
  .Pricing__content {
    flex-direction: column;
    align-items: center;
  }
  .PlanCard:not(:last-child) {
    margin-bottom: 104px;
  }
  .PlanCard {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
  .PlanCard_dummy {
    display: none;
  }
  .PlanCard__price {
    margin-top: 14px;
    margin-bottom: 32px;
  }
  .PlanCard__name {
    margin-top: -20px;
  }
  .PlanCard__featureListCaption {
    margin-top: 50px;
    margin-bottom: 3px;
  }
  .PlanCard__feature {
    justify-content: space-between;
    align-items: flex-start;
  }
  .PlanCard__featureDiscount {
    margin-left: auto;
    position: relative;
    top: initial;
    width: auto;
    margin-top: 12px;
    display: inline-block;
  }
  .PlanCard__featureValue {
    text-align: right;
  }
  .PlanCard__description {
    margin-bottom: 10px;
  }
  .ColoredArea {
    flex-direction: column;
  }
  .ColoredArea .Btn {
    margin-left: 0;
    margin-top: 24px;
  }
}

.ColoredArea {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 36px;
  color: white;
  text-align: center;
}
.ColoredArea_wa {
  background-color: #25d366;
}
.ColoredArea__text {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ColoredArea__text svg {
  margin-right: 12px;
}
.ColoredArea .Btn {
  margin-left: 48px;
}
