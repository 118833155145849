@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

$container-max-widths: (
  xl: 1200px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 10px,
  md: 768px,
  lg: 1024px,
);

/**
Точно такая же переменная есть в variables.less. Меняешь тут - поменяй и там
 */
$grid-gutter-width: 24px;

@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/nav';

.container {
  padding-left: 0;
  padding-right: 0;
}
