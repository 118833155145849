@import '../variables';

.Island {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 32px 0;
  margin: 128px 0;

  .Btn {
    margin-top: 32px;
  }

  p {
    margin-top: 17px;
    color: @smoke_color;
    font-weight: 400;
    line-height: 22px;
    max-width: 70%;
  }

  &:after {
    transform: scaleX(-1);
  }

  &__caption_small {
    width: 50%;
  }
  &__price {
    font-size: 18px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  &__logo {
    margin-bottom: 20px;
  }
  &__channels {
    margin-top: 20px;

    svg {
      margin-right: 20px;
    }
  }
  &__grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    max-width: 50%;

    div {
      display: flex;
      text-align: left;

      &:before {
        content: '';
        display: inline-block;
        height: 22px;
        width: 22px;
        background-image: url('../../../public/images/assets/check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 28px;
      }
    }
  }

  &_brand {
    background-color: @brand_color;

    h2,
    p {
      color: #fff !important;
    }
  }
  &_platinum {
    background-color: @platinum_color;

    h2,
    p {
      color: #fff !important;
    }
  }
  &_whatsapp {
    background-color: @wa_color;

    h2,
    p {
      color: #fff !important;
    }
  }
  &_instagram {
    background-color: @ig_color;

    h2,
    p {
      color: #fff !important;
    }
  }
  &_show {
    background-color: @snow_color;
  }
  &_sign {
    .Island__content:after {
      margin-top: 30px;
      content: '';
      width: 18px;
      height: 16px;
      background-image: url('../../../public/images/quickley-sign-xs.svg');
    }
  }
  &_row & {
    &__content {
      flex-direction: row;
      justify-content: space-between;
    }
    &__caption {
      flex-shrink: 0;
    }
  }
}

@media (min-width: 768px) {
  .Island_pattern:before,
  .Island_pattern:after {
    content: '';
    width: 114px;
    height: 92px;
    background-image: url('../../../public/images/pattern.svg');
  }
}
@media (max-width: 767px) {
  .Island {
    justify-content: center;
  }
  .Island_row {
    .Island__content {
      flex-direction: column;
    }
    .Island__grid {
      margin-top: 24px;
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .Island p {
    font-size: 13px;
  }
}
