@import '../variables';

.Image {
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 0;

  img {
    width: 100%;
    border-radius: 4px;
    .mixin_box_shadow();
  }

  @media (max-width: 767px) {
    display: none;
  }
}
