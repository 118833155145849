/**
Точно такая же переменная есть в bootstrap.sass. Меняешь тут - поменяй и там
 */
/**
  MIXINS
 */
.mixin_box_shadow_block {
  box-shadow: 0 0 0 1px rgba(165, 184, 207, 0.1), 0 8px 16px rgba(165, 184, 207, 0.08);
}
.mixin_box_shadow {
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04), 0 62.9566px 72.7037px rgba(0, 0, 0, 0.0393684), 0 39.436px 60.3952px rgba(0, 0, 0, 0.0378242), 0 21.9738px 26.706px rgba(0, 0, 0, 0.0351912), 0 9.33239px 4.959px rgba(0, 0, 0, 0.0311936), 0 1.2653px 0.418345px rgba(0, 0, 0, 0.0253402), 0px -2.02863px 0px rgba(0, 0, 0, 0.0165283);
}
.mixin_popup_container {
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04), 0 62.9566px 72.7037px rgba(0, 0, 0, 0.0393684), 0 39.436px 60.3952px rgba(0, 0, 0, 0.0378242), 0 21.9738px 26.706px rgba(0, 0, 0, 0.0351912), 0 9.33239px 4.959px rgba(0, 0, 0, 0.0311936), 0 1.2653px 0.418345px rgba(0, 0, 0, 0.0253402), 0px -2.02863px 0px rgba(0, 0, 0, 0.0165283);
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
}
.quickley_link {
  color: #0072ff;
  cursor: pointer;
}
.quickley_link:hover {
  color: #0067e6;
  text-decoration: none;
}
.quickley_link.disabled {
  color: #66aaff;
}
.quickley_link-error {
  color: #ff017d;
  cursor: pointer;
}
.quickley_link-error:hover {
  color: #e70071;
  text-decoration: none;
}
.quickley_link-error.disabled {
  color: #ff67b1;
}
.quickley_link-warning {
  color: #ef6544;
  cursor: pointer;
}
.quickley_link-warning:hover {
  color: #ed522d;
  text-decoration: none;
}
.quickley_link-warning.disabled {
  color: #f7b2a2;
}
.quickley_linkBtn {
  color: #8191a5;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
}
.quickley_linkBtn:hover {
  color: #72849a;
  text-decoration: none;
}
.text_overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#HamburgerCheckbox,
.Hamburger {
  float: right;
  display: none;
}
.Hamburger {
  width: 20px;
  margin-left: 16px;
  background: url('../../../public/images/hamburger.svg') no-repeat center center;
}
.Navigation__ul {
  display: flex;
  align-items: center;
  font-size: 15px;
  list-style: none;
}
.Navigation__li:not(:last-child) {
  margin-right: 32px;
}
.Navigation__li_phone {
  position: relative;
  color: #0072ff;
  font-weight: 700;
}
.Navigation__li_phone:before {
  content: '';
  position: absolute;
  background: url('../../../public/images/phone.svg') no-repeat;
  background-size: 16px;
  width: 16px;
  height: 16px;
  left: -24px;
  top: 12px;
}
.Navigation__inlineLogin {
  float: right;
  color: #0072ff;
  font-size: 13px;
}
@media (min-width: 1200px) {
  .Navigation {
    float: right;
  }
  #HamburgerCheckbox,
  .Hamburger,
  .Navigation__inlineLogin {
    display: none;
  }
}
@media (max-width: 1199px) {
  .Navigation__nav {
    position: absolute;
    right: 30px;
    top: 74px;
    display: flex;
    padding: 20px 20px 40px;
    background: #fff;
    width: 300px;
    border-radius: 4px;
    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04), 0 62.9566px 72.7037px rgba(0, 0, 0, 0.0393684), 0 39.436px 60.3952px rgba(0, 0, 0, 0.0378242), 0 21.9738px 26.706px rgba(0, 0, 0, 0.0351912), 0 9.33239px 4.959px rgba(0, 0, 0, 0.0311936), 0 1.2653px 0.418345px rgba(0, 0, 0, 0.0253402), 0px -2.02863px 0px rgba(0, 0, 0, 0.0165283);
  }
  .Navigation__ul {
    display: block;
    width: 100%;
  }
  .Navigation__li {
    height: 40px;
    line-height: 40px;
    margin-right: 0;
    width: 100%;
    border-bottom: 1px solid #c1ccdb;
  }
  .Navigation__li:last-child {
    border: none;
  }
  .Navigation__li_phone {
    display: none;
  }
  .Navigation__login {
    width: 100%;
    margin-top: 16px;
  }
  .Hamburger {
    display: block;
  }
  #HamburgerCheckbox:not(:checked) ~ .Navigation__nav {
    display: none;
  }
}
@media (max-width: 767px) {
  .Navigation__nav {
    left: 0;
    right: 0;
    top: 64px;
    width: auto;
    box-shadow: none;
  }
  .Hamburger {
    display: block;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
ul,
ol {
  margin-bottom: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}
button,
input,
select,
textarea {
  font: inherit;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../../public/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */ url('../../../public/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */ url('../../../public/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Semibold.eot');
  /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../../public/fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */ url('../../../public/fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */ url('../../../public/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../../public/fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */ url('../../../public/fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */ url('../../../public/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Lato';
  src: url('../../../public/fonts/Lato-Black.eot');
  /* IE9 Compat Modes */
  src: url('../../../public/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../../public/fonts/Lato-Black.woff2') format('woff2'), /* Modern Browsers */ url('../../../public/fonts/Lato-Black.woff') format('woff'), /* Modern Browsers */ url('../../../public/fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
.grecaptcha-badge {
  visibility: hidden;
}
body {
  color: #1a2b49;
  font-size: 16px;
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.2;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
}
h1 {
  font-size: 36px;
  font-weight: 600;
}
h2 {
  font-size: 30px;
  font-weight: 600;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}
h4 {
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 767px) {
  h1 {
    font-size: 24px;
  }
  h2,
  h3 {
    font-size: 18px;
  }
}
.container_top {
  padding-top: 76px;
}
.container,
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 64px;
  line-height: 40px;
  font-weight: 600;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.header-banner-visible {
  top: 64px;
}
.Section_fullView {
  min-height: calc(100vh - 64px);
}
/* FOOTER */
footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #787878;
  margin-top: 60px;
}
.footer__credentials {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #a5b8cf;
  font-size: 11px;
  max-width: 1500px;
}
.footer__lang {
  display: inline-block;
  color: #333;
  margin-left: 2px;
  font-weight: 700;
}
a.footer__lang {
  color: #0072ff;
}
.footer__lang:not(:last-child) {
  padding-right: 4px;
  border-right: 1px solid #a5b8cf;
}
@media (max-width: 767px) {
  footer {
    padding-bottom: 40px;
  }
  .footer__copyright {
    display: none;
  }
  .footer__credentials {
    flex-direction: column;
    align-items: center;
  }
  .footer__langSwitcher:after {
    margin: 30px auto 0;
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    background: url('../../../public/images/quickley-sign.svg') no-repeat center center;
    background-size: contain;
  }
}
/* END FOOTER */
/* BANNER */
.Banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 64px;
  line-height: 40px;
}
.Banner__desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 100%;
}
.Banner__mobile {
  display: none;
}
.Banner__left {
  font-size: 20px;
  font-weight: bold;
}
.Banner__discount {
  font-size: 50px;
  font-weight: bold;
}
.Banner__text {
  font-weight: bold;
}
.Banner__text .Btn {
  margin-left: 16px;
}
.Banner_whatsappOffer {
  color: #f9fbfc;
  background: #1a2b49 url('../../../public/images/whatsapp/confetti.svg') repeat-x center center;
}
@media (max-width: 1200px) {
  .Banner__desktop {
    display: none;
  }
  .Banner__mobile {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}
/* END BANNER */
/* NAVIGATION */
.Logo {
  display: inline-flex;
  align-items: center;
  font-size: 26px;
}
.Logo:hover {
  color: inherit;
  text-decoration: none;
}
.Logo__img {
  margin-right: 17px;
}
@media (max-width: 767px) {
  .Logo__caption {
    display: none;
  }
  .Logo__img {
    margin-top: 4px;
    margin-right: 0;
  }
}
main {
  margin-top: 64px;
}
/* END NAVIGATION */
/* BTN */
.Btn {
  font-weight: bold;
  border-radius: 4px;
  padding: 6px 36px;
  box-sizing: border-box;
  font-size: 15px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.Btn_primary {
  color: #fff;
  background-color: #0072ff;
}
.Btn_primary:hover {
  color: #fff;
  background-color: #0067e6;
  text-decoration: none;
}
.Btn_white {
  color: #1a2b49;
  background-color: #f9fbfc;
}
.Btn_disabled {
  cursor: not-allowed;
  color: #a5b8cf;
  border: 1px solid #a5b8cf;
}
.Btn_disabled:hover,
.Btn_disabled.Btn_filled {
  color: white;
  background-color: #a5b8cf;
}
.Btn_light {
  color: #fff;
  border: 1px solid #fff;
}
.Btn_light:hover {
  color: #0072ff;
  background-color: #fff;
}
/* BTN END */
/* MOBILE BADGE */
.MobileBadges {
  display: flex;
}
.MobileBadges a:first-child {
  margin-right: 15px;
}
/* END MOBILE BADGE */
.Blocks {
  max-width: 1500px;
  margin: 128px auto;
}
.Blocks h2 {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blocks__content {
  display: flex;
  justify-content: space-around;
}
.Block {
  max-width: 300px;
  text-align: center;
}
.Block h3 {
  padding: 15px 0;
}
.Block p {
  color: #8191a5;
}
@media (max-width: 767px) {
  .Blocks__content {
    flex-direction: column;
  }
  .Block {
    margin: 0 auto 50px;
  }
  .Block:last-child {
    margin-bottom: 0;
  }
}
.Feature {
  max-width: 1500px;
  margin: 256px auto;
}
.Feature h2 {
  height: 100px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Feature h3 {
  margin-bottom: 20px;
}
.Feature p {
  color: #8191a5;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 30px;
}
.Feature ul {
  list-style: none;
  color: #8191a5;
  margin-bottom: 30px;
}
.Feature ul li {
  margin-bottom: 15px;
}
.Feature ul li:before {
  content: ' ';
  display: inline-block;
  background-color: #0072ff;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 8px;
}
.Feature__body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Feature__caption {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 600;
}
.Feature__img {
  max-width: 650px;
  width: 100%;
}
.Feature__img_medium {
  max-width: 450px;
}
.Feature__comingSoon {
  color: #8191a5;
  border-radius: 4px;
  border: 1px solid #8191a5;
  padding: 4px 14px;
  display: inline-block;
}
.Feature_reverse .Feature__body {
  flex-direction: row-reverse;
}
@media (min-width: 768px) {
  .Feature__content {
    width: 400px;
  }
}
@media (max-width: 767px) {
  .Feature {
    margin: 128px auto;
  }
  .Feature__body {
    flex-direction: column !important;
  }
  .Feature__img {
    width: 100%;
  }
  .Feature__content {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .Feature__caption {
    margin-bottom: 23px;
    font-size: 24px;
  }
  .Feature p {
    margin-bottom: 26px;
  }
}
.Intro {
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 64px;
}
.Intro h1 {
  font-weight: normal;
}
.Intro h1 b {
  color: #0072ff;
}
.Intro p {
  margin: 20px auto 30px;
  color: #8191a5;
  font-weight: 400;
  line-height: 22px;
  max-width: 500px;
}
.Intro p b {
  color: #1a2b49;
}
.Intro__content {
  text-align: center;
}
.Intro__content_left {
  width: 500px;
  text-align: left;
}
.Intro__content_left .Intro__cta {
  justify-content: left;
}
.Intro__content_left .Intro__label {
  justify-content: left;
}
.Intro__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.Intro__label {
  color: #8191a5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Intro__label svg {
  margin-right: 6px;
}
.Intro__label_right {
  margin-left: 12px;
}
.Intro__label_bottom {
  margin-top: 12px;
}
.Intro__img {
  width: 35%;
}
.Intro__img img {
  width: 100%;
}
.Intro__img_ig {
  width: 60%;
  margin-right: -200px;
}
.Intro__wa {
  margin-top: 20px;
}
.Intro__ig {
  margin-bottom: 24px;
}
.Intro__integration {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Intro__integration svg {
  margin: 0 10px;
}
.Intro__scroll {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: url('../../../public/images/scroll.svg') no-repeat center center;
}
.Intro__hexes {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Intro__emoji {
  width: 40px;
  height: 40px;
}
@media (min-width: 768px) {
  .Intro_half {
    min-height: calc(50vh - 64px);
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .Intro__content:before {
    display: none;
  }
  .Intro__content {
    text-align: center;
  }
  .Intro__cta {
    justify-content: center;
    flex-direction: column;
  }
  .Intro__label {
    margin-top: 15px;
    font-size: 12px;
  }
  .Intro__img {
    display: none;
  }
}
@media (min-width: 768px) {
  .Intro h1 {
    width: 800px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .Intro {
    margin-top: -64px;
    height: 100vh;
  }
  .Intro__description {
    display: none;
  }
  .Intro__scroll {
    display: none;
  }
  #Intro__scroll {
    display: none;
  }
  .Intro__content {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .Intro__content:before {
    content: '';
  }
}
.Image {
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 0;
}
.Image img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04), 0 62.9566px 72.7037px rgba(0, 0, 0, 0.0393684), 0 39.436px 60.3952px rgba(0, 0, 0, 0.0378242), 0 21.9738px 26.706px rgba(0, 0, 0, 0.0351912), 0 9.33239px 4.959px rgba(0, 0, 0, 0.0311936), 0 1.2653px 0.418345px rgba(0, 0, 0, 0.0253402), 0px -2.02863px 0px rgba(0, 0, 0, 0.0165283);
}
@media (max-width: 767px) {
  .Image {
    display: none;
  }
}
.Reel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../../public/images/polygon.svg') no-repeat center;
  min-height: calc(100vh - 64px);
}
@media (max-width: 767px) {
  .Reel {
    background: none;
    min-height: auto;
    margin: 200px 0;
  }
}
.Reel__header {
  text-align: center;
}
.Reel__header p {
  margin-top: 22px;
  color: #8191a5;
  font-weight: 600;
}
.Reel__content {
  margin-top: 22px;
  overflow-y: hidden;
  display: flex;
  align-items: baseline;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
}
.Reel__content:after {
  content: '';
  padding: 20px;
}
.Reel__content::-webkit-scrollbar {
  width: 0;
}
.Reel__sign {
  margin-right: 47px;
  align-self: center;
}
.ReelCard {
  position: relative;
  width: 306px;
  height: 396px;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.ReelCard:not(:last-child) {
  margin-right: 24px;
}
.ReelCard__img {
  height: 204px;
  margin-bottom: 20px;
  background: no-repeat center;
  background-size: cover;
  opacity: 0.3;
  filter: blur(2px);
}
.ReelCard__img_comments {
  background-image: url('../../../public/images/live-chat/support-en.jpg');
}
.ReelCard__img_chatLanding {
  background-image: url('../../../public/images/live-chat/landing-en.jpg');
}
.ReelCard__img_managers {
  background-image: url('../../../public/images/live-chat/team-en.jpg');
}
.ReelCard__img_app {
  background-image: url('../../../public/images/live-chat/mobile-app-en.jpg');
}
.ReelCard__img_integrations {
  background-image: url('../../../public/images/whatsapp/automation-en.jpg');
}
.ReelCard__img_contacts {
  background-image: url('../../../public/images/live-chat/contacts-en.jpg');
}
.ReelCard h4 {
  margin-top: 24px;
  margin-bottom: 16px;
  min-height: 56px;
  font-size: 24px;
  font-weight: 600;
}
.ReelCard p {
  color: #8191a5;
}
.ReelCard__content {
  padding: 0 24px 24px;
}
@media (max-width: 767px) {
  .ReelCard__content {
    margin-top: 24px;
  }
}
.ReelCard__tag {
  position: absolute;
  height: 60px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8191a5;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 24px;
}
.ReelCard__tag:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #ffffff 0, rgba(255, 255, 255, 0.6) 75%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
}
@media (max-width: 767px) {
  .ReelCard__tag {
    display: none;
  }
}
.Island {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 32px 0;
  margin: 128px 0;
}
.Island .Btn {
  margin-top: 32px;
}
.Island p {
  margin-top: 17px;
  color: #8191a5;
  font-weight: 400;
  line-height: 22px;
  max-width: 70%;
}
.Island:after {
  transform: scaleX(-1);
}
.Island__caption_small {
  width: 50%;
}
.Island__price {
  font-size: 18px;
}
.Island__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
.Island__logo {
  margin-bottom: 20px;
}
.Island__channels {
  margin-top: 20px;
}
.Island__channels svg {
  margin-right: 20px;
}
.Island__grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  max-width: 50%;
}
.Island__grid div {
  display: flex;
  text-align: left;
}
.Island__grid div:before {
  content: '';
  display: inline-block;
  height: 22px;
  width: 22px;
  background-image: url('../../../public/images/assets/check.svg');
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 28px;
}
.Island_brand {
  background-color: #0072ff;
}
.Island_brand h2,
.Island_brand p {
  color: #fff !important;
}
.Island_platinum {
  background-color: #1a2b49;
}
.Island_platinum h2,
.Island_platinum p {
  color: #fff !important;
}
.Island_whatsapp {
  background-color: #25d366;
}
.Island_whatsapp h2,
.Island_whatsapp p {
  color: #fff !important;
}
.Island_instagram {
  background-color: #f10176;
}
.Island_instagram h2,
.Island_instagram p {
  color: #fff !important;
}
.Island_show {
  background-color: #f9fbfc;
}
.Island_sign .Island__content:after {
  margin-top: 30px;
  content: '';
  width: 18px;
  height: 16px;
  background-image: url('../../../public/images/quickley-sign-xs.svg');
}
.Island_row .Island__content {
  flex-direction: row;
  justify-content: space-between;
}
.Island_row .Island__caption {
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .Island_pattern:before,
  .Island_pattern:after {
    content: '';
    width: 114px;
    height: 92px;
    background-image: url('../../../public/images/pattern.svg');
  }
}
@media (max-width: 767px) {
  .Island {
    justify-content: center;
  }
  .Island_row .Island__content {
    flex-direction: column;
  }
  .Island_row .Island__grid {
    margin-top: 24px;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .Island p {
    font-size: 13px;
  }
}
.Legal {
  max-width: 800px;
  margin: 0 auto;
}
.Legal h2 {
  margin-bottom: 100px;
}
.Legal h3 {
  margin-top: 32px;
}
.Legal h4 {
  margin-top: 24px;
}
.Legal p {
  margin: 15px 0;
  color: #1a2b49;
}
.Legal ul {
  padding-left: 40px;
}
.Legal a {
  color: #0072ff;
  cursor: pointer;
}
.Legal a:hover {
  color: #0067e6;
  text-decoration: none;
}
.Legal a.disabled {
  color: #66aaff;
}
.Legal table {
  color: #1a2b49;
}
.Legal table thead {
  text-transform: uppercase;
  font-size: 10px;
}
.Legal table thead th {
  border: none;
  padding: 0.75rem;
  vertical-align: top;
}
.Legal table tbody tr td {
  border: none;
  box-shadow: 0 -1px #e6e9f1;
  padding: 0.75rem;
  vertical-align: top;
}
.Legal table.table-hover tbody tr:hover {
  color: #1a2b49;
  background-color: #f9fbfc;
}
.Pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1024px;
  margin: 0 auto;
}
.Pricing__header {
  margin: 0 auto 100px;
  text-align: center;
}
.Pricing__header h1 {
  margin-bottom: 50px;
}
.Pricing__header h1 b {
  color: #0072ff;
}
.Pricing__header p {
  max-width: 70%;
  margin: 20px auto 30px;
  font-weight: 400;
  line-height: 22px;
}
.Pricing__content {
  display: flex;
  justify-content: space-between;
}
.PlanCard {
  width: 50%;
  font-size: 13px;
}
.PlanCard:first-child .PlanCard__description:before {
  display: none;
}
.PlanCard__header {
  position: sticky;
  top: 64px;
  background-color: #fff;
  z-index: 1;
  padding-bottom: 8px;
}
.PlanCard__name {
  color: #0072ff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
}
.PlanCard__price {
  margin-top: 34px;
  margin-bottom: 44px;
  font-size: 36px;
  font-weight: bolder;
}
.PlanCard__currency {
  font-size: 18px;
  font-weight: normal;
}
.PlanCard__description {
  margin-bottom: 24px;
  min-height: 70px;
  font-size: 14px;
  max-width: 95%;
}
.PlanCard__description:before {
  margin-bottom: 18px;
  display: block;
  content: '';
  width: 24px;
  height: 2px;
  background-color: #c1ccdb;
}
.PlanCard__featureListCaption {
  margin-top: 64px;
  margin-bottom: 24px;
  color: #1a2b49;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
}
.PlanCard__featureListCaption:first-child {
  margin-top: 24px;
}
.PlanCard__feature {
  height: 37px;
  display: flex;
  min-height: 18px;
  padding: 10px 0;
  align-items: center;
  font-size: 14px;
  position: relative;
}
.PlanCard__featureName {
  margin-right: 10px;
}
.PlanCard__featureName a {
  color: #0072ff;
}
.PlanCard__featureName_hovered:hover + .PlanCard__featureDescription {
  opacity: 1;
  z-index: 1;
}
.PlanCard__featureName_hovered span {
  border-bottom: 1px dashed #8191a5;
  cursor: pointer;
}
.PlanCard__featureHelp {
  font-size: 12px;
  color: #8191a5;
}
.PlanCard__featureDescription {
  position: absolute;
  max-width: 320px;
  font-size: 12px;
  line-height: 18px;
  top: 45px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(165, 184, 207, 0.1), 0 8px 16px rgba(165, 184, 207, 0.08);
  padding: 20px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
}
.PlanCard__cta {
  display: flex;
  flex-direction: column;
  max-width: 260px;
}
.PlanCard__ctaText {
  color: #8191a5;
  text-align: center;
  margin-top: 8px;
}
@media (min-width: 768px) {
  .Pricing__description {
    margin: 0 auto;
    width: 373px;
  }
  .PlanCard:not(.PlanCard_dummy) .PlanCard__featureListCaption {
    visibility: hidden;
  }
  .PlanCard:not(.PlanCard_dummy) .PlanCard__featureName {
    display: none;
  }
  .PlanCard .Btn {
    min-width: 128px;
  }
}
@media (max-width: 767px) {
  .Pricing {
    padding-top: 0;
  }
  .Pricing__content {
    flex-direction: column;
    align-items: center;
  }
  .PlanCard:not(:last-child) {
    margin-bottom: 104px;
  }
  .PlanCard {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
  .PlanCard_dummy {
    display: none;
  }
  .PlanCard__price {
    margin-top: 14px;
    margin-bottom: 32px;
  }
  .PlanCard__name {
    margin-top: -20px;
  }
  .PlanCard__featureListCaption {
    margin-top: 50px;
    margin-bottom: 3px;
  }
  .PlanCard__feature {
    justify-content: space-between;
    align-items: flex-start;
  }
  .PlanCard__featureDiscount {
    margin-left: auto;
    position: relative;
    top: initial;
    width: auto;
    margin-top: 12px;
    display: inline-block;
  }
  .PlanCard__featureValue {
    text-align: right;
  }
  .PlanCard__description {
    margin-bottom: 10px;
  }
  .ColoredArea {
    flex-direction: column;
  }
  .ColoredArea .Btn {
    margin-left: 0;
    margin-top: 24px;
  }
}
.ColoredArea {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 36px;
  color: white;
  text-align: center;
}
.ColoredArea_wa {
  background-color: #25d366;
}
.ColoredArea__text {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ColoredArea__text svg {
  margin-right: 12px;
}
.ColoredArea .Btn {
  margin-left: 48px;
}
.Help {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 200px;
}
.Help h1 {
  margin-bottom: 24px;
}
.Help h3 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.Help p {
  margin-top: 16px;
  margin-bottom: 16px;
  color: inherit;
  font-weight: 600;
}
.Help ol,
.Help ul {
  padding-left: 40px;
}
.Help a {
  color: #0072ff;
  cursor: pointer;
}
.Help a:hover {
  color: #0067e6;
  text-decoration: none;
}
.Help a.disabled {
  color: #66aaff;
}
.Help__content {
  width: 100%;
}
.Help__image {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}
.Help__image img {
  width: 100%;
}
.Quote {
  margin: 128px auto;
}
.Quote h2 {
  text-align: center;
  margin: 0 auto 48px;
  max-width: 700px;
}
.Quote h2:before {
  content: '';
  width: 114px;
  height: 92px;
  background-image: url('../../../public/images/assets/quote.svg');
  position: absolute;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.Quote img {
  width: 64px;
  height: 64px;
  margin-right: 24px;
}
.Quote__person {
  display: flex;
  justify-content: center;
}
.Quote__personInfo {
  align-self: center;
}
.Quote__personName {
  font-weight: bold;
}
.Quote__personTitle {
  color: #8191a5;
}
.Brands {
  margin: 64px auto;
}
.Brands h2 {
  height: 100px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Brands__logos {
  display: flex;
  justify-content: center;
}
.Brands__logos svg {
  margin: 0 24px;
}
.Links {
  margin: 64px auto;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Links a {
  margin: 0 24px;
}
.NotFoundPage {
  display: flex;
  justify-content: center;
  align-items: center;
}
