@import '../variables';

#HamburgerCheckbox,
.Hamburger {
  float: right;
  display: none;
}

.Hamburger {
  width: 20px;
  margin-left: 16px;
  background: url('../../../public/images/hamburger.svg') no-repeat center center;
}

.Navigation {
  &__ul {
    display: flex;
    align-items: center;
    font-size: 15px;
    list-style: none;
  }
  &__li {
    &:not(:last-child) {
      margin-right: 32px;
    }

    &_phone {
      position: relative;
      color: @brand_color;
      font-weight: 700;

      &:before {
        content: '';
        position: absolute;
        background: url('../../../public/images/phone.svg') no-repeat;
        background-size: 16px;
        width: 16px;
        height: 16px;
        left: -24px;
        top: 12px;
      }
    }
  }
  &__inlineLogin {
    float: right;
    color: @brand_color;
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .Navigation {
    float: right;
  }
  #HamburgerCheckbox,
  .Hamburger,
  .Navigation__inlineLogin {
    display: none;
  }
}

@media (max-width: 1199px) {
  .Navigation {
    &__nav {
      position: absolute;
      right: 30px;
      top: 74px;
      display: flex;
      padding: 20px 20px 40px;
      background: #fff;
      width: 300px;
      border-radius: 4px;
      .mixin_box_shadow();
    }
    &__ul {
      display: block;
      width: 100%;
    }
    &__li {
      height: 40px;
      line-height: 40px;
      margin-right: 0;
      width: 100%;
      border-bottom: 1px solid @carbon_color;

      &:last-child {
        border: none;
      }

      &_phone {
        display: none;
      }
    }
    &__login {
      width: 100%;
      margin-top: 16px;
    }
  }
  .Hamburger {
    display: block;
  }
  #HamburgerCheckbox:not(:checked) ~ .Navigation__nav {
    display: none;
  }
}

@media (max-width: 767px) {
  .Navigation {
    &__nav {
      left: 0;
      right: 0;
      top: 64px;
      width: auto;
      box-shadow: none;
    }
  }
  .Hamburger {
    display: block;
  }
}
