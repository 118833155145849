@import '../variables';

.Help {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 200px;

  h1 {
    margin-bottom: 24px;
  }
  h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 16px;
    color: inherit;
    font-weight: 600;
  }
  ol,
  ul {
    padding-left: 40px;
  }
  a {
    .quickley_link();
  }

  &__content {
    width: 100%;
  }
  &__image {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }
}
