@import '../variables';

.Quote {
  margin: 128px auto;

  h2 {
    text-align: center;
    margin: 0 auto 48px;
    max-width: 700px;

    &:before {
      content: '';
      width: 114px;
      height: 92px;
      background-image: url('../../../public/images/assets/quote.svg');
      position: absolute;
      z-index: -1;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }
  img {
    width: 64px;
    height: 64px;
    margin-right: 24px;
  }

  &__person {
    display: flex;
    justify-content: center;

    &Info {
      align-self: center;
    }
    &Name {
      font-weight: bold;
    }
    &Title {
      color: @smoke_color;
    }
    &Subtitle {
    }
  }
}
