@import 'variables';

@font-face {
  font-family: Lato;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url('../../public/fonts/Lato-Regular.woff2') format('woff2'),
    url('../../public/fonts/Lato-Regular.woff') format('woff'),
    url('../../public/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: bold;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url('../../public/fonts/Lato-Bold.woff2') format('woff2'),
    url('../../public/fonts/Lato-Bold.woff') format('woff'),
    url('../../public/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url('../../public/fonts/Lato-Semibold.woff2') format('woff2'),
    url('../../public/fonts/Lato-Semibold.woff') format('woff'),
    url('../../public/fonts/Lato-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url('../../public/fonts/Lato-Black.woff2') format('woff2'),
    url('../../public/fonts/Lato-Black.woff') format('woff'),
    url('../../public/fonts/Lato-Black.ttf') format('truetype');
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  height: 100%;
  color: @platinum_color;
}

:focus {
  outline: none;
}

input {
  padding: 0;
}

#root {
  height: 100%;
}

.full-height {
  height: 100%;

  & > div {
    min-width: 768px;
  }
}

a {
  .quickley_link();
}

label {
  color: @smoke_color;
  margin: 0;
  transform-origin: left center;
  transform: scale(0.9);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0;
}

h1 {
  font-weight: bold;
  font-size: 24px;
  margin: 5px 0;
}

h2 {
  font-size: 15px;
  margin: 11px 0;
}

.btn {
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  height: 40px;
  transition: none;
  border: none;
  border-radius: 4px;
  padding: 6px 36px;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  &.btn-main {
    background: @logo_color;
    color: white;
    border-color: @logo_color;

    &:hover {
      background: @main_color;
      color: #fff;
      border-color: @main_color;
    }
  }

  &.btn-sm {
    font-weight: 600;
    height: 32px;
    padding: 6px 16px;
  }
}

.btn-mixin(@color) {
  background-color: @color;
  color: #fff;
  &:hover {
    background-color: darken(@color, 5%);
    color: #fff;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: darken(@color, 10%);
    color: #fff;
  }
  &:disabled,
  &.disabled {
    background-color: lighten(@color, 20%);
    color: #fff;
    opacity: 1;
    cursor: default;
    border: none;
  }
}

.btn-outline-mixin(@color) {
  .btn-mixin(@color);
  border: 2px solid @color;
  background-color: #fff;
  color: @color;
  &:hover {
    border-color: darken(@color, 5%);
  }
  &:not(:disabled):not(.disabled):active {
    border-color: darken(@color, 10%);
  }
  &:disabled,
  &.disabled {
    border: 2px solid lighten(@color, 20%);
    background-color: #fff;
    color: lighten(@color, 20%);
  }
}

.btn-primary {
  .btn-mixin(@brand_color);
}
.btn-outline-primary {
  .btn-outline-mixin(@brand_color);
}

.btn-secondary {
  .btn-mixin(@smoke_color);
}
.btn-outline-secondary {
  .btn-outline-mixin(@smoke_color);
}

.btn-success {
  .btn-mixin(@emerald);
}
.btn-outline-success {
  .btn-outline-mixin(@emerald);
}

.btn.btn-danger {
  .btn-mixin(@fire_rose_color);
}
.btn-outline-danger {
  .btn-outline-mixin(@fire_rose_color);
}

.btn.btn-info {
  .btn-mixin(@purple);
}
.btn-outline-info {
  .btn-outline-mixin(@purple);
}

.btn.btn-warning {
  .btn-mixin(@orange);
}
.btn-outline-warning {
  .btn-outline-mixin(@orange);
}

.btn-link {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

/* table */
.table {
  color: @platinum_color;

  thead {
    text-transform: uppercase;
    font-size: 10px;

    th {
      border: none;
    }
  }

  tbody {
    tr td {
      border: none;
      box-shadow: 0 -1px @dirty_jon_snow_color;
    }
  }

  &.table-hover {
    tbody {
      tr:hover {
        color: @platinum_color;
        background-color: @snow_color;
      }
    }
  }
}
/* end table */

/* tabs */
.nav-tabs {
  border: none;

  .nav-link {
    border: none;
    margin: 0;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;
    padding: 0 12px;

    &.active {
      color: @platinum_color;
      background-color: transparent !important;
    }
  }
}
/* end tabs*/

.Footer__input {
  display: flex;
  justify-content: flex-end;
}

.Grid--noPadding {
  padding: 0;
}

.row {
  flex-shrink: 0;
}

input.form-control {
  height: 40px;
  font-size: 13px;
}

textarea.form-control {
  font-size: 13px;
}

.alert {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 12px 0;
  font-weight: 600;

  &-primary {
    color: @brand_color;
  }
  &-danger {
    color: @fire_rose_color;
  }
  &-warning {
    color: @orange;
  }
}

.emoji-img {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
}

.disabled-blur {
  position: relative;
  opacity: 0.5;
  filter: blur(1px);
  cursor: default;
  pointer-events: none;
  user-select: none;
  max-width: 100%;
}
