@import '../variables';

.Intro {
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 64px;

  h1 {
    font-weight: normal;

    b {
      color: @brand_color;
    }
  }
  p {
    margin: 20px auto 30px;
    color: @smoke_color;
    font-weight: 400;
    line-height: 22px;
    max-width: 500px;

    b {
      color: @platinum_color;
    }
  }

  &__content {
    text-align: center;

    &_left {
      width: 500px;
      text-align: left;

      .Intro__cta {
        justify-content: left;
      }
      .Intro__label {
        justify-content: left;
      }
    }
  }
  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  &__label {
    color: @smoke_color;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 6px;
    }

    &_right {
      margin-left: 12px;
    }
    &_bottom {
      margin-top: 12px;
    }
  }
  &__img {
    width: 35%;

    img {
      width: 100%;
    }

    &_ig {
      width: 60%;
      margin-right: -200px;
    }
  }
  &__wa {
    margin-top: 20px;
  }
  &__ig {
    margin-bottom: 24px;
  }
  &__integration {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0 10px;
    }
  }
  &__scroll {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url('../../../public/images/scroll.svg') no-repeat center center;
  }
  &__hexes {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__emoji {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: @grid-breakpoints[md]) {
  .Intro_half {
    min-height: calc(50vh - 64px);
    align-items: flex-end;
  }
}

@media (max-width: 767px) {
  .Intro__content:before {
    display: none;
  }
  .Intro__content {
    text-align: center;
  }
  .Intro__cta {
    justify-content: center;
    flex-direction: column;
  }
  .Intro__label {
    margin-top: 15px;
    font-size: 12px;
  }
  .Intro__img {
    display: none;
  }
}
@media (min-width: 768px) {
  .Intro h1 {
    width: 800px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .Intro {
    margin-top: -64px;
    height: 100vh;
  }

  .Intro__description {
    display: none;
  }

  .Intro__scroll {
    display: none;
  }

  #Intro__scroll {
    display: none;
  }

  .Intro__content {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .Intro__content:before {
    content: '';
  }
}
